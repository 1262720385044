<template>
  <div app-header :class="[theme, roll, scrolled, footerVisible ? 'footerVisible' : '']">
    <header>
      <aside class="drawer" :class="{ on: menuOpen }">
        <div class="drawer-holder">
          <nav class="drawer-menu">
            <ul>
              <li><router-link to="/platform">PLATFORM</router-link></li>
              <li><router-link to="/frame">FRAME</router-link></li>
<!--              <li @mouseenter="over('cartridge')" @mouseleave="out">-->
              <li>
                <router-link to="/cartridge" @click.native.prevent="onClick" event="none">CARTRIDGE</router-link>
                <ul :class="{on: onSub === 'cartridge'}">
                  <li><router-link to="/cartridge/skin"><img src="/img/common/ico-header-skin.svg" alt="">Skin</router-link></li>
                  <li><router-link to="/cartridge/air-handling"><img src="/img/common/ico-header-airHandling.svg" alt="">Air Handling</router-link></li>
                  <li><router-link to="/cartridge/ict"><img src="/img/common/ico-header-ict.svg" alt="">ICT</router-link></li>
                </ul>
              </li>
              <li><router-link to="/os">OS</router-link></li>
              <li><router-link to="/works">WORKS</router-link></li>
              <li><router-link to="/contact">CONTACT</router-link></li>
              <li><router-link to="/data">DATA</router-link></li>
            </ul>
          </nav>
        </div>
      </aside>
      <router-link to="/" class="logo"><h1 class="ir">NEWTYPE</h1></router-link>
      <a class="btn-hamburger" :class="{ on: menuOpen }" @click="toggleDrawer"></a>
    </header>
  </div>
</template>

<script>
import { values } from '@/utils';

export default {
  name: 'AppHeader',
  data() {
    return {
      menuOpen: false,
      onSub: false,
      footerVisible: false,
    };
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    roll() {
      return this.$store.state.browser.scrollTop > 10 && this.$store.state.browser.scrollDirection === 'down' && 'roll';
    },
    scrolled() {
      return this.$store.state.browser.scrolled;
    },
  },
  watch: {
    $route: 'reset',
  },
  methods: {
    reset() {
      this.menuOpen = false;
      this.onSub = this.$route.meta.id;

      this.$scroll.release();
      // setTimeout( () => {
      //     Array.from(this.$el.querySelectorAll('.arr')).forEach(e => {
      //       console.log(e.querySelectorAll('.router-link-active').length);
      //       if (e.querySelectorAll('.router-link-active').length) {
      //         e.classList.add('on');
      //       } else e.classList.remove('on');
      //     })
      // }, 100);
    },
    over(id) {
      if (this.touch) return;
      this.onSub = id;
    },
    out() {
      if (this.touch) return;
      this.onSub = this.$route.meta.id;
    },
    toggleDrawer(){
      if (!this.menuOpen) {
        this.$scroll.freeze();
        this.menuOpen = true;
      } else {
        this.$scroll.release();
        this.menuOpen = false;
      }
    },
    onClick(e) {
      if (this.matchedMedia in values('MS','MM','ML','TP')) {
        const el = e.currentTarget.parentNode;
        el.classList.toggle('on');
      } else {
        this.$router.push(e.currentTarget.getAttribute('href'));
      }
    },
    onScroll() {
      const footer = document.querySelector('[footer]');
      this.footerVisible = footer.getBoundingClientRect().top - window.innerHeight < 0;
    },
  },
  mounted() {
    const els = Array.from(this.$el.querySelectorAll('.drawer-menu > ul > li'));
    els.forEach(e => (e.childElementCount > 1 && e.classList.add('arr')));
    this.reset();

    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[app-header] { .fix; .lt; .wf; z-index: 49;
  &.roll header { .t-y(-100%); }
  &.roll.footerVisible, &.footerVisible { header { .t-y(0); }}
}
header { .rel; .bgc(#fff); .wf; .h(60); .t-y(0); transition: transform 0.6s ease; z-index: 2;
  > a { .block; .wh(24); .contain; .no-repeat; .abs; .lt(24,18);
    &.logo { .bg('/img/common/logo.svg'); .wh(180,24); }
    &.btn-hamburger { .bg('/img/common/ico-hamburger.svg'); .bg-c; .rt(24,18);
      &.on { .bg('/img/common/ico-close.svg'); }
    }
  }
}
.drawer { .abs; .lt; .wf; .plex;
  .drawer-holder { .abs; .rt; .wf; .fvh; .t-x(100%); .bgc(#fff); overflow-y:auto; .-box; transition: transform 0.4s ease;
    .drawer-menu { .p(76,24,40);
      > ul > li + li { .-t(#DADADA); }
      > ul > li.on {
        ul { .p(16,0,32); .max-h(200); }
      }
      ul ul {  .-box; .m(0,16); .p(0); .max-h(0); .crop; transition: max-height 0.4s, padding 0.4s;
        li + li { .mt(16); }
        a { .fs(20,1); .ls(0); .p; .pretendard; }
        img { .hide; }
      }
      a { .block; .rel; .fs(24,1); .ls(1.2px); .p(16,0); .c(#1e1e1e); .o(.5);
        &.router-link-active { .o(1); }
      }
      .arr > a:after { .cnt; .abs; .rt(0,8); .wh(40); .contain('/img/common/ico-submenu-arr.svg'); transform: rotate(180deg); .o(.5); }
      .arr.on > a:after { transform: rotate(0deg); .o(1); }
    }
    .copyright { .abs; .lb(20,15); .fs(12,20); .c(#b4b4b4); }
  }
  &.on {
    .drawer-backdrop { .l(0); .o(1); pointer-events: auto; }
    .drawer-holder { .t-x(0); }
  }
}

@media (min-width: @screen-ds-min) {
  .drop-shadow() { box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12); }
  .menu-disappear() {
    .hidden; .o(0); transform: translateY(-10);
    transition: transform .35s cubic-bezier(.19,1,.22,1), opacity .35s cubic-bezier(.19,1,.22,1), visibility 0s .35s;
  }
  .menu-appear() {
    .visible; .o(1); transform: translateY(0); transition: transform .85s cubic-bezier(.19,1,.22,1), opacity .55s cubic-bezier(.19,1,.22,1);
  }

  header { .h(80); .drop-shadow;
    > a.logo { .wh(232,32); .lt(80,24); }
    > a.btn-hamburger { .hide; }
  }
  .drawer { .rel; .hf;
    .drawer-holder { .rel; .wf; .hf; .t-x(0); .flex; .justify-end; overflow-y: visible;
      .drawer-menu { .p; .hf; .mr(50);
        > ul { .flex; .hf;
          .arr a:after { .hide; }
          > li {
            > a { .hf; .flex; .align-center; .-box; .p(0,14); .m(0, 10); .fs(16,1); .ls(.7px); .o(.5); .tr-o(.3s);
              &:hover { .o(1); }
              &.router-link-active { .o(1);
                &:after { .cnt; .abs; .lb(0,0); .r(0); .h(2); .bgc(#000); .w(auto); }
              }
            }
          }
          li + li { .m; }
          li.on ul, ul { .flex; .justify-center; .abs; .lt; .wf; .drop-shadow; .mt(80); .p; .menu-disappear; .bgc(#fff); .-t(#eee); .hide;
            &.on { .menu-appear; }
            .scrolled &.on { .menu-disappear; }
            li + li { .m; .ml(56); }
            a { .tc; .fs(14,20); .p(18,0); .w(148); .o(.5); .tr-o(.3s);
              &:hover { .o(1); }
              &.router-link-active { .o(1);
                &:after { .cnt; .abs; .lb(13,0); .r(13); .h(2); .bgc(#000); }
              }
            }
            img { .block; .w(48); .m(0,auto,2); }
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  .drawer .drawer-holder {
    .drawer-menu {
      > ul > li > a { .m(0, 30); }
    }
  }
}
</style>
