<template>
  <div id="app" :class="[theme, touch ? 'touch' : 'no-touch', fetching, roll, search, first]">
    <AppHeader/>
    <scroll-holder container-class="scroll-container" body-class="scroll-body">
      <router-view />
      <AppFooter />
    </scroll-holder>
    <div class="fixed-holder"></div>
  </div>
</template>

<script>
import AppHeader from '@/views/common/AppHeader';
import AppFooter from '@/views/common/AppFooter';

export default {
  name: 'App',
  components: { AppHeader, AppFooter },
  data() {
    return {
      topStyle: false,
    };
  },
  metaInfo() {
    return {
      title: this.$t('meta.title'),
      meta: [
        { vmid: 'title', content: 'fit', },
        { vmid: 'description', content: 'fit website', },
        { vmid: 'keywords', content: 'fit,samoo', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
  computed: {
    theme() { return this.$store.state.theme; },
    fetching() { return this.$store.state.fetchingAsyncData && 'fetching'; },
    roll() {
      return this.$store.state.browser.scrollDirection === 'down' && 'roll'; //&& this.$scroll.getScrollTop() > 0 && 'roll';
    },
    search() {
      return this.$store.state.searchMode && 'search';
    },
    first() {
      return this.$store.state.isFirst && 'first';
    },
  },
  mounted() {
  },
}
</script>

<style lang="less">
@import "~@/less/common.less";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.loading { .flex-center; .fix; .lt; .f;
  i { .block; .wh(80);
    border: 3px solid rgba(0,0,0,.3);
    border-top-color: #000;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
    .dark & {
      border: 3px solid rgba(255,255,255,.3);
      border-top-color: #fff;
    }
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

[scroll-body] { transform: translateY(0); transition: transform .5s cubic-bezier(.59,.01,.28,1);
  .search & { transform: translateY(300px);
    @media (min-width: @screen-dm-min) { transform: translateY(450px); }
  }
  .first & { transition-duration: 0s; }
}
</style>
