import { values, sleep, toggleClass, isEmpty } from '@/utils';
import moment from 'moment';
import _ from 'lodash';

export default {
  data() {
    return {
      cdnHost: process.env.VUE_APP_CDN_HOST,
      isFirst: false,
    };
  },
  computed: {
    matchedMedia() {
      return this.$store.state.browser.matchedMedia;
    },
    routeMeta() {
      return this.$route.matched.reduce((acc, route) => ({ ...acc, ...route.meta }), {});
    },
    frozen() {
      return this.$store.state.scrollAgency.frozen;
    },
    languages() {
      return { ko: '한국어', en: 'ENGLISH', jp: '日本語', tw: '中文-繁體', vn: 'Tiếng Việt' };
    },
    language() {
      return this.languages[this.lang];
    },
    root() {
      return this.$route.meta.root;
    },
    fullCodeList() {
      return this.$store.state.codes;
    },
    touch() {
      return this.$store.state.browser.touch;
    },
  },
  methods: {
    isEmpty, values, sleep, toggleClass,
    getRegDtt(dtt) {
      const format = {
        ko: 'YYYY.MM.DD',
        en: 'MMM DD, YYYY',
        tw: 'YYYY年MM月DD日',
        jp: 'YYYY年 MM月 DD日',
      };
      return moment(dtt).format(format[this.lang]);
    },
    getIndex(target, list) {
      return Array.prototype.indexOf.call(list, target);
    },
    codeList(par, omit) {
      return _.filter(this.$store.state.codes, row => row.parentCode === par && _.every(omit, o => `${ par }-${ o }` !== row.code));
    },
    codeLabel(code) {
      return _.find(this.$store.state.codes, row => row.code === code)?.label;
    },
    codeData(code) {
      return _.find(this.$store.state.codes, row => row.code === code)?.data;
    },
    codeRouter(code) {
      return _.find(this.$store.state.codes, row => row.code === code)?.data?.route;
    },
    codeParent(code) {
      return _.find(this.$store.state.codes, row => row.code === code)?.parentCode;
    },
    mediaVal(val) {
      return val && ~val.indexOf('#') && (val = val.split('#'), { location: val[0], sq: +val[1] });
    },
    matchMediaType(src, type) {
      if (typeof src !== 'string' || this.isEmpty(src)) return null;
      if (/\.(jpg|jpeg|png|gif)/gi.exec(src)) return 'image' === type ? src : null;
      if (/\.(mp4|webm)/gi.exec(src)) return 'video' === type ? src : null;
      if (/\.(mpeg|ogg|wav)/gi.exec(src)) return 'audio' === type ? src : null;
      return null;
    },
    getMediaType(src) {
      if (typeof src !== 'string' || this.isEmpty(src)) return null;
      if (/\.(jpg|jpeg|png|gif)/gi.exec(src)) return 'image';
      if (/\.(mp4|webm)/gi.exec(src)) return 'video';
      if (/\.(mpeg|ogg|wav)/gi.exec(src)) return 'audio';
      return '';
    },
    enterToBr(cont) {
      if (!cont) return '';
      return cont.replace(/[\r\n]/g, '<br>').replace(/(\s{2,})/g, (match, space) => space.replace(/\s/g, '&nbsp;'));
    },
    formDate(timestamp) {
      return !timestamp ? '-' : moment(timestamp).format('YYYY.MM.DD');
    },
    async changeLang(lang) {
      if (lang === this.lang) return;
      // location.href = `/${ lang === 'ko' ? '' : lang }`;
      await this.$router.push(`/${ lang === 'ko' ? '' : lang }`);
    },
    localeLink(to) {
      if (!to || !this.$i18n) return;
      to = to.replace(/^\/(ko|en|jp|tw|vn)\//i,'/');
      this.$router.push(`${ this.$i18n('path') }${ to }`);
    },
    isPlayContent(type) {
      return type.includes('play');
    },
    getSlugUrl(data) {
      return `/${ data.root || this.root }/${ this.isPlayContent(data.type) ? 'viewer' : 'article' }/${ data.slug }`;
    },
    linkTo(to) {
      if (this.isEmpty(to)) return;
      if (/^http/.test(to)) { // outlink
        window.open(to);
      } else if (/#\d+$/.test(to)) { // 파일 다운로드
        window.open(`${ this.cdnHost }/${ to.split('#')[0] }`);
      } else if (/^\/(ko|en|jp|tw|vn)\//.test(to)) { // 다국어 링크
        this.$router.push(to.replace(/^\/ko\//, '/'));
      } else {
        this.localeLink(to);
      }
    },
    videoIsPlaying(video) {
      return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;
    },
  },
};
