<template>
  <div footer>
    <a class="go-top" @click="goTop"></a>
    <div class="contact">
      <p>제품 체험 및 상담문의</p>
      <router-link to="/contact">Contact</router-link>
    </div>
    <ul class="menu">
      <li><router-link to="/platform">PLATFORM</router-link></li>
      <li><router-link to="/frame">FRAME</router-link></li>
      <li><router-link to="/cartridge">CARTRIDGE</router-link></li>
      <li><router-link to="/os">OS</router-link></li>
      <li><router-link to="/works">WORKS</router-link></li>
      <li><router-link to="/contact">CONTACT</router-link></li>
      <li><router-link to="/data">DATA</router-link></li>
    </ul>
    <p class="copyright"><a href="https://samoo.com/" target="_blank">©️ 2023. SAMOO Architects & Engineers ALL RIGHTS RESERVED.</a></p>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  lexicon: 'footer',
  data() {
    return {
      sitemapOpen:false,
      langOpen:false,
    };
  },
  methods: {
    goTop() {
      this.$scroll.scrollMove(0, 2000);
    },
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[footer] { .rel; .bgc(#000); color: #fff; .p(50,20); .tc;
  .go-top { .abs; .lt(50%,-68); .t-xc; .wh(48); .block; .bg('/img/common/ico-top.svg'); .tr-a(0.3s);
    @{hover} { transform:translateY(-10px); }
  }
  .contact {
    p { .fs(22,31); .bold; }
    a { .ib; .p(14,64); .fs(14,1); .br(3); .-a(#fff); .mt(16); .medium; .tr-a(0.3s);
      @{hover} { .bgc(#fff); .c(#2e2e2e); }
    }
  }
  .menu { .mt(50); .tl; .plex;
    li { .fs(15,1); .p(10,0); .-b(#292929); &:first-of-type { .-t(#292929); }
      a { @{hover} { .underline; }}
    }
  }
  .copyright { .mt(30); .fs(12,19); }
}
@media (min-width: @screen-tp-min) {
  [footer] { .p(64,0);
    .contact {
      a { .mt(16); }
    }
    .menu { .mt(48); .tc; .flex; .justify-center;
      li { .p(0,15); .-b; &:first-of-type { .-t; }}
    }
    .copyright { .mt(24); .fs(13,20); }
  }
}
@media (min-width: @screen-tl-min) {
  [footer] {
    .go-top { transform: translateX(0); .rt(32,-80); left: auto; }
  }
}
</style>
