import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

const page = path => () => import(`@/views/pages${ path }.vue`);

Vue.use(VueRouter);
Vue.use(Meta);

export default () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => null,
  routes: [
    {
      path: '/',
      redirect: 'platform'
    },
    {
      path: '/platform',
      meta: { id: 'platform' },
      component: page('/platform'),
    },
    {
      path: '/frame',
      meta: { id: 'frame' },
      component: page('/frame'),
    },
    {
      path: `/cartridge`,
      component: page('/language-root'),
      children: [
        {
          path: '/',
          redirect: 'skin',
        },
        {
          path: 'skin',
          meta: { id: 'skin' },
          component: page('/cartridge-skin'),
        },
        {
          path: 'air-handling',
          meta: { id: 'air' },
          component: page('/cartridge-air'),
        },
        {
          path: 'ict',
          meta: { id: 'ict' },
          component: page('/cartridge-ict'),
        },
      ],
    },
    {
      path: '/os',
      component: page('/os'),
    },
    {
      path: '/works',
      component: page('/works'),
    },
    {
      path: '/contact',
      component: page('/contact'),
    },
    {
      path: '/data',
      component: page('/download'),
    },
    // {
    //   path: '/test',
    //   component: page('/parallax-test'),
    // },
  ],
})
